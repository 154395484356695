<template>
    <div>
     
          <b-card-code title="تعديل مورد">
            <validation-observer ref="simple">

<validation-observer
  #default="{ handleSubmit }"
  ref="refFormObserver"
>
  <b-form
    @submit.prevent="handleSubmit(updateVendor)"
    @reset.prevent="resetForm"
  >
    <b-row>
      <b-col
        md="3"
        xl="3"
      >
        <validation-provider
          #default="{ errors }"
          rules="required"
        >
          <b-form-group
            label-for="title"
            :state="errors.length > 0 ? false : null"
          >

                <label> الاسم التجاري</label>
            <b-form-input
              id="input-title"
              v-model="Form.name"
              type="text"
            />
          </b-form-group>
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>

      <b-col
        md="3"
        xl="3"
      >
        <validation-provider
          #default="{ errors }"
          rules="required"
        >
          <b-form-group
            label-for="code"
            :state="errors.length > 0 ? false : null"
          >

            <label> الرقم</label>
            <b-form-input
              id="input-default"
              v-model="Form.phone"
              type="number"
            />
          </b-form-group>
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>
      <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-group
                    label-for="title"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  المفوض بالتسليم</label>
                    <b-form-input
                      id="input-title"
                      v-model="Form.assisted"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="الايميل"
          label-for="email"
        >
          <validation-provider
            #default="{ errors }"
            name="email"
            rules="email|required"
          >
            <b-form-input
              id="email"
              v-model="Form.email"
              type="email"
            />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          label="العنوان"
          label-for="location"
        >
          <validation-provider
            #default="{ errors }"
            name="location"
            rules="required"
          >
            <b-form-input
              id="location"
              v-model="Form.location"
              type="text"
            />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
           <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="المجال"
                  label-for="field"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="field"
                    rules=""
                  >
                    <b-form-input
                      id="field"
                      v-model="Form.field"
                      type="text"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
                  <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="المالك"
                  label-for="owner"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="owner"
                    rules=""
                  >
                    <b-form-input
                      id="owner"
                      v-model="Form.owner"
                      type="text"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
                  <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="الكود"
                  label-for="code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="code"
                    rules=""
                  >
                    <b-form-input
                      id="code"
                      v-model="Form.code"
                      type="text"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
                <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="تاريخ البداية"
                  label-for="code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="code"
                    rules=""
                  >
                      <flat-pickr v-model="Form.beginning_date"    @input="(val) => $emit('update:dateFilter', val)"    class="form-control"
                    placeholder="حدد تاريخ " />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
      <b-col
        md="3"
        xl="3"
      >
        <validation-provider
          #default="{ errors }"
          rules="required"
        >
          <b-form-group
            label-for="brand"
            :state="errors.length > 0 ? false : null"
          >

            <label>  التفاصيل</label>
            <b-form-input
              id="input-brand_name"
              v-model="Form.notes"
              type="text"
            />
          </b-form-group>
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>

      <b-col
        class="border-Primary "
        md="12"
        xl="12"
      >
        <b-button

          variant="outline-primary"
          type="submit"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle"> تعديل المورد</span>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</validation-observer>

</validation-observer>
          </b-card-code>
      
      <b-col md="4" xl="2">
        <b-button variant="purple " @click="$router.go(-1)">
          <span class="align-middle">رجوع</span>
        </b-button>
      </b-col>
    </div>
  </template>
  
  <script>
  import "vue-good-table/dist/vue-good-table.css";
  import { VueGoodTable } from "vue-good-table";
  import vSelect from "vue-select";
  import { required, max, is_earlier } from "@validations";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

  import {
    BRow,
    BCol,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
  } from "bootstrap-vue";
  import Cleave from "vue-cleave-component";
  // eslint-disable-next-line import/no-extraneous-dependencies
  import "cleave.js/dist/addons/cleave-phone.us";
  
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  import {
    BFormDatepicker,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BButton,
    BForm,
    BFormInvalidFeedback,
  } from "bootstrap-vue";
  export default {
  
  
    components: {
      ToastificationContent,
      required,
      flatPickr,
      BDropdown,
      BDropdownItem,
      BDropdownItemButton,
      BFormInvalidFeedback,
      VueGoodTable,
      BForm,
      ValidationObserver,
      ValidationProvider,
  
      BCardCode,
      BButton,
      BFormInput,
      BFormFile,
      BFormGroup,
      BInputGroupPrepend,
      BInputGroup,
      BFormDatepicker,
      Cleave,
      BFormTextarea,
      vSelect,
      BFormCheckbox,
      BRow,
  
      BCol,
    },
  
    data() {
      return {
        name: "",
        number:'',
        donor_id:'',
        optionsDonor:[],
        Form:{ name: '',
      phone: '',
      assisted: '',
      email: '',
      location: '',
      notes: '',},
        en_name: "",
        item_category_id:'',
        
        
        searchTerm: "",
        id: "",
     
      };
    },
  
    created() {
      this.getVendor()

    },
  
    methods: {
  
      updateVendor() {
        return new Promise((resolve, reject) => {
          this.$refs.simple
            .validate()
            .then((success) => {
              if (success) {
                let data =[]
              
                  data = {
                    name:this.name,
          number:this.number,
          donor_id:this.donor_id,
          assisted:  this.Form.assisted
        };
            
        this.$http.put(`/api/v1/vendor/${this.$route.params.id}`, this.Form).then((res) => {
          //console.log(res);
          
          this.$swal({
            title: "",
            text: "تمت تعديل  بنجاح",
            icon: "success",
            confirmButtonText: "موافق",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
         
        });
      }})})
      },
      
  
      getVendor() {
        this.$http.get(`/api/v1/vendor/${this.$route.params.id}`).then((res) => {
          //console.log('rewards', res.data.data)
          this.Form=res.data.data
  
        });
      },
  
      //
    },
  };
  </script> 
  
  <style scoped>
  label {
    color: rgba(112, 192, 203, 255);
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .btn-purple {
    color: white;
    background-color: #7e7a9f;
    margin-top: 25px;
  }
  
  span {
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .align-left {
    left: 50px;
  }
  .but {
    top: 40px;
  }
  </style>